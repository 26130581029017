import { MAINTAIN_PERMISSION, GROWERS_CROPS } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "edit-crops"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _c("div", {
    staticClass: "header px-5 pt-3 pb-4 mb-5 d-flex justify-content-between align-items-center"
  }, [_c("div", {
    staticClass: "d-flex align-items-center flex-wrap"
  }, [_c("a-icon", {
    staticClass: "arrow-left pr-3",
    attrs: {
      "type": "arrow-left"
    },
    on: {
      "click": function($event) {
        return _vm.onBack();
      }
    }
  }), _c("span", {
    staticClass: "pr-3 header-title"
  }, [_vm._v(_vm._s(_vm.editForm.code) + " - " + _vm._s(_vm.editForm.name))])], 1)]), _c("div", {
    staticClass: "body mx-5"
  }, [_c("a-row", {
    attrs: {
      "gutter": [24, 24],
      "type": "flex"
    }
  }, [_c("a-col", {
    attrs: {
      "span": 24
    }
  }, [_c("div", {
    staticClass: "card p-4"
  }, [_c("h6", {
    staticClass: "h6 mb-3"
  }, [_vm._v("General Information")]), _c("a-row", {
    attrs: {
      "gutter": 24
    }
  }, [_c("a-col", {
    attrs: {
      "span": 6
    }
  }, [_c("text-input", {
    attrs: {
      "disabled": "",
      "label": "Crop Code",
      "value": _vm.editForm.code
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 6
    }
  }, [_c("text-input", {
    attrs: {
      "disabled": "",
      "label": "Crop Name",
      "value": _vm.editForm.name
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 6
    }
  }, [_c("select-input", {
    attrs: {
      "label": "Crop Family",
      "reference": "grower.crop-families",
      "source": "id",
      "source-label": "name",
      "value": _vm.editForm.cropFamilyId,
      "disabled": !_vm.hasMaintainPermission
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("cropFamilyId", $event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 24
    }
  }, [_c("p", {
    staticClass: "b8 mb-0 mt-4"
  }, [_vm._v(" Last modified by " + _vm._s(_vm.editForm.updatedBy) + " on " + _vm._s(_vm._f("moment")(_vm.editForm.updatedAt, "DD/MM/YYYY HH:mm")) + " ")])])], 1)], 1)]), _c("a-col", {
    attrs: {
      "span": 24,
      "xl": {
        span: 12
      }
    }
  }, [_c("div", {
    staticClass: "card p-4"
  }, [_c("h6", {
    staticClass: "h6 mb-3"
  }, [_vm._v("Deductions")]), _c("a-row", {
    attrs: {
      "gutter": [24, 16]
    }
  }, [_c("a-col", {
    attrs: {
      "span": 8
    }
  }, [_c("switch-input", {
    attrs: {
      "label": "Warning Tolerance",
      "value": _vm.editForm.deductWarningTolerance,
      "disabled": !_vm.hasMaintainPermission
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("deductWarningTolerance", $event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 16
    }
  }, [_c("text-input", {
    attrs: {
      "label": "Limit",
      "disabled": !_vm.editForm.deductWarningTolerance,
      "value": _vm.editForm.deductWarningLimit
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("deductWarningLimit", $event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 24
    }
  }, [_c("text-input", {
    attrs: {
      "label": "Warning Text",
      "disabled": !_vm.editForm.deductWarningTolerance,
      "value": _vm.editForm.deductWarningText
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("deductWarningText", $event);
      }
    }
  }), _c("div", {
    staticClass: "divider my-3"
  })], 1), _c("a-col", {
    attrs: {
      "span": 8
    }
  }, [_c("switch-input", {
    attrs: {
      "label": "Observed Tolerance",
      "value": _vm.editForm.deductObservedTolerance,
      "disabled": !_vm.hasMaintainPermission
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("deductObservedTolerance", $event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 8
    }
  }, [_c("text-input", {
    attrs: {
      "label": "Observed Limit",
      "disabled": !_vm.editForm.deductObservedTolerance,
      "value": _vm.editForm.deductObservedLimit
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("deductObservedLimit", $event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 8
    }
  }, [_c("text-input", {
    attrs: {
      "label": "Applied Percentage",
      "value": _vm.editForm.deductAppliedPercentage,
      "suffix": "%",
      "disabled": !_vm.hasMaintainPermission
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("deductAppliedPercentage", $event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 24
    }
  }, [_c("div", {
    staticClass: "divider my-3"
  }), _c("a-row", {
    attrs: {
      "gutter": 24
    }
  }, [_c("a-col", {
    attrs: {
      "span": 8
    }
  }, [_c("text-input", {
    attrs: {
      "label": "Average Deductions",
      "value": _vm.editForm.avgDednPercent,
      "suffix": "%",
      "disabled": !_vm.hasMaintainPermission
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("avgDednPercent", $event);
      }
    }
  })], 1)], 1)], 1)], 1)], 1)]), _c("a-col", {
    attrs: {
      "span": 24,
      "xl": {
        span: 12
      }
    }
  }, [_c("div", {
    staticClass: "card h-100 p-4"
  }, [_c("h6", {
    staticClass: "h6 mb-3"
  }, [_vm._v("Grade")]), _c("a-row", {
    attrs: {
      "gutter": [24, 16]
    }
  }, [_c("a-col", {
    attrs: {
      "span": 8
    }
  }, [_c("switch-input", {
    attrs: {
      "label": "Warning Tolerance",
      "value": _vm.editForm.gradeWarningTolerance,
      "disabled": !_vm.hasMaintainPermission
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("gradeWarningTolerance", $event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 16
    }
  }, [_c("text-input", {
    attrs: {
      "label": "Limit",
      "disabled": !_vm.editForm.gradeWarningTolerance,
      "value": _vm.editForm.gradeWarningLimit
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("gradeWarningLimit", $event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 24
    }
  }, [_c("text-input", {
    attrs: {
      "label": "Warning Text",
      "disabled": !_vm.editForm.gradeWarningTolerance,
      "value": _vm.editForm.gradeWarningText
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("gradeWarningText", $event);
      }
    }
  }), _c("div", {
    staticClass: "divider my-3"
  })], 1), _c("a-col", {
    attrs: {
      "span": 8
    }
  }, [_c("switch-input", {
    attrs: {
      "label": "Observed Tolerance",
      "value": _vm.editForm.gradeObservedTolerance,
      "disabled": !_vm.hasMaintainPermission
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("gradeObservedTolerance", $event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 8
    }
  }, [_c("text-input", {
    attrs: {
      "label": "Observed Limit",
      "disabled": !_vm.editForm.gradeObservedTolerance,
      "value": _vm.editForm.gradeObservedLimit
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("gradeObservedLimit", $event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 8
    }
  }, [_c("text-input", {
    attrs: {
      "label": "Applied Percentage",
      "value": _vm.editForm.gradeAppliedPercentage,
      "suffix": "%",
      "disabled": !_vm.hasMaintainPermission
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("gradeAppliedPercentage", $event);
      }
    }
  })], 1)], 1)], 1)]), _c("a-col", {
    attrs: {
      "span": 24
    }
  }, [_c("div", {
    staticClass: "card p-4"
  }, [_c("h6", {
    staticClass: "h6 mb-3"
  }, [_vm._v("System Control")]), _c("a-row", {
    attrs: {
      "gutter": 24
    }
  }, [_c("a-col", {
    attrs: {
      "span": 6
    }
  }, [_c("checkbox-input", {
    staticClass: "pb-4",
    attrs: {
      "label": "Sample Flag",
      "value": _vm.editForm.sampleFlag,
      "disabled": !_vm.hasMaintainPermission
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("sampleFlag", $event);
      }
    }
  }), _c("checkbox-input", {
    staticClass: "pb-4",
    attrs: {
      "label": "Multiple Sample Type",
      "value": _vm.editForm.multipleSampleType,
      "disabled": !_vm.hasMaintainPermission
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("multipleSampleType", $event);
      }
    }
  }), _c("checkbox-input", {
    staticClass: "pb-4",
    attrs: {
      "label": "Grade By Weight",
      "value": _vm.editForm.gradeByWeight,
      "disabled": !_vm.hasMaintainPermission
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("gradeByWeight", $event);
      }
    }
  }), _c("checkbox-input", {
    staticClass: "pb-4",
    attrs: {
      "label": "Attributes",
      "value": _vm.editForm.attributes,
      "disabled": !_vm.hasMaintainPermission
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("attributes", $event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 6
    }
  }, [_c("checkbox-input", {
    staticClass: "pb-4",
    attrs: {
      "label": "Grade Override",
      "value": _vm.editForm.gradeOverride,
      "disabled": !_vm.hasMaintainPermission
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("gradeOverride", $event);
      }
    }
  }), _c("checkbox-input", {
    staticClass: "pb-4",
    attrs: {
      "label": "Bunker Required",
      "value": _vm.editForm.bunkerReqdFlag,
      "disabled": !_vm.hasMaintainPermission
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("bunkerReqdFlag", $event);
      }
    }
  }), _c("checkbox-input", {
    staticClass: "pb-4",
    attrs: {
      "label": "Paddock Sample",
      "value": _vm.editForm.paddockSampleFlag,
      "disabled": !_vm.hasMaintainPermission
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("paddockSampleFlag", $event);
      }
    }
  }), _c("checkbox-input", {
    staticClass: "pb-4",
    attrs: {
      "label": "Yield Observation",
      "value": _vm.editForm.yieldObsFlag,
      "disabled": !_vm.hasMaintainPermission
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("yieldObsFlag", $event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 6
    }
  }, [_c("checkbox-input", {
    staticClass: "pb-4",
    attrs: {
      "label": "Multiple Weight",
      "value": _vm.editForm.multipleWeight,
      "disabled": !_vm.hasMaintainPermission
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("multipleWeight", $event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 6
    }
  }, [_c("switch-input", {
    attrs: {
      "label": "Bin Label",
      "value": _vm.editForm.binLabel,
      "disabled": !_vm.hasMaintainPermission
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("binLabel", $event);
      }
    }
  }), _c("text-input", {
    staticClass: "mt-3",
    attrs: {
      "label": "Label Format - Intake",
      "disabled": !_vm.editForm.binLabel,
      "value": _vm.editForm.labelFormat
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("labelFormat", $event);
      }
    }
  }), _c("text-input", {
    staticClass: "mt-3",
    attrs: {
      "label": "Label Format - Selected Range of Bins",
      "disabled": !_vm.editForm.binLabel,
      "value": _vm.editForm.labelFormatRange
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("labelFormatRange", $event);
      }
    }
  })], 1)], 1)], 1)])], 1), _c("div", {
    staticClass: "divider mt-4 mb-3"
  }), _c("a-row", {
    staticClass: "w-100 mb-4",
    attrs: {
      "gutter": 0,
      "justify": "end",
      "type": "flex"
    }
  }, [_vm.hasMaintainPermission ? _c("a-button", {
    on: {
      "click": _vm.onCancelClick
    }
  }, [_vm._v("Cancel")]) : _vm._e(), _vm.hasMaintainPermission ? _c("a-button", {
    staticClass: "ml-2",
    attrs: {
      "loading": _vm.isSubmitting,
      "type": "primary"
    },
    on: {
      "click": _vm.onSaveClick
    }
  }, [_vm._v("Save")]) : _vm._e()], 1)], 1)], 1);
};
var staticRenderFns$1 = [];
var EditCrops_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "EditCrops",
  inject: ["resourceName", "redirectRoute", "crud", "can"],
  data() {
    return {
      isSubmitting: false,
      entity: {},
      editForm: {},
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "crops",
          title: "Crops",
          path: "/watties-grower/crops"
        }
      ]
    };
  },
  computed: {
    hasMaintainPermission() {
      return this.can(MAINTAIN_PERMISSION);
    }
  },
  async mounted() {
    await this.crud.fetchEntity(), this.entity = this.crud.getEntity();
    this.storeValues(this.entity);
    this.itemsMenu = [
      ...this.itemsMenu,
      {
        key: `${this.entity && this.entity.name}`,
        title: `${this.entity && this.entity.name} - ${this.entity && this.entity.code}`,
        path: `/watties-grower/crops/${this.entity && this.entity.id}`
      }
    ];
  },
  methods: {
    storeValue(resourceKey, value) {
      this.editForm = {
        ...this.editForm,
        [resourceKey]: value
      };
      this.crud.updateEntity("editForm", { resourceKey, value });
    },
    storeValues(entity) {
      Object.entries(entity).forEach(([resourceKey, value]) => this.storeValue(resourceKey, value));
    },
    onBack() {
      this.$router.push("/watties-grower/crops");
    },
    onCancelClick() {
      this.$router.push(this.redirectRoute);
    },
    async onSaveClick() {
      this.isSubmitting = true;
      try {
        await this.crud.submitEntity("replace");
      } finally {
        this.isSubmitting = false;
      }
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "1233aa5f", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditCrops = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "edit-crops-containers"
  }, [_c("resource", {
    attrs: {
      "name": "grower.crop-families",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.crops",
      "api-url": _vm.apiUrl,
      "redirect-route": "/watties-grower/crops",
      "page": _vm.page
    }
  }, [_c("edit-crops")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditCrops
  },
  data() {
    return {
      EditCrops,
      apiUrl,
      page: GROWERS_CROPS
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
